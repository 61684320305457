.cta {
  background-color: #dfe4ea;
  height: 112px;
  display: flex;
  align-items: center;
}

.buttons {
  justify-content: space-between;
}

.leftArrow {
  float: left;
}

.rightArrow {
  float: right;
}

.buttons .Frame {
  background-color: #18214d;
}

@media screen and (max-width: 1200px) {
  .cta h1 {
    padding: 0 50px !important;
  }
}
@media screen and (max-width: 450px) {
  .cta h1 {
    padding: 0 !important;
    max-width: 340px;
    margin: 0 auto !important;
  }
}
