.textRow {
    padding-top: 38px;
}
.description {
    padding: 0 20px !important;
}
.buttonsRow {
    justify-content: center;
    padding-top: 38px;
}

.dark .logosRow svg path {
    fill: #ffffff;
}

@media screen and (max-width: 450px) {
    .gridWrapper {
        padding-left: 15px;
        padding-right: 15px;
    }
    .description {
        padding: 0 !important;
    }
    .textRow {
        padding-top: 30px;
    }
    .button {
        height: 50px !important;
        font-size: 18px !important;
        line-height: 50px !important;
    }
}
