.imgPreview img {
  border-radius: 8px;
  cursor: pointer;
  pointer-events: all;
}

.imgPreview .caption {
  display: none;
}

.caption {
  display: flex;
  justify-content: center;
  margin-top: 5px !important;
}

.container {
  margin: 80px auto;
}

@media screen and (max-width: 768px) {
  .container {
    margin: 40px 0;
  }
  .imgHide {
    display: none;
  }
  .imgPreview img {
    border-radius: 0;
  }
  .container .imgPreview .caption {
    display: flex;
    font-size: 14px !important;
  }
}
