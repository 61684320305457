.gridWrapper {
  display: flex;
  flex-direction: column;
}

.cta {
  padding: 68px 0 52px;
}

p.description {
  color: white !important;
}

.cta h1 {
  padding: 0 30px !important;
}

.buttons {
  justify-content: space-between;
}

.leftArrow {
  float: left;
}

.rightArrow {
  float: right;
}

@media screen and (max-width: 1200px) {
  .cta h1 {
    padding: 0 50px !important;
  }
  .description {
    padding: 14px 40px 0 !important;
  }
}

@media screen and (max-width: 991px) {
  .descriptionBreak {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .cta {
    padding-top: 16px;
  }
  .cta .title {
    font-size: 32px;
  }
  .gridWrapper .titleRow {
    order: 2;
  }
  .gridWrapper .arrowRow {
    order: 1;
    margin-bottom: 16px;
  }
  .gridWrapper .descriptionRow {
    order: 3;
  }
}

@media screen and (max-width: 450px) {
  .gridWrapper {
    padding: 0 15px;
  }
  .cta h1 {
    padding: 0 !important;
    max-width: 340px;
    margin: 0 auto !important;
  }
  .description {
    padding: 14px 0 0 0 !important;
    max-width: 340px;
    margin: 0 auto !important;
  }
  .buttonRow {
    padding-top: 25px;
  }
}
