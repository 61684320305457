.buttonRow {
    width: 100%;

    display:grid;
    grid-template-columns: auto auto 1fr;
    grid-gap: 15px;
    padding-top: 36px;
}
.subtitle {
    font-weight: bold !important;
    padding-top: 16px;
    max-width: 420px;
}
.description {
    padding-top: 20px !important;
    max-width: 420px;
}
.middleLevel {
    padding-top: 0;
}
@media screen and (max-width: 450px) {
    .col {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .buttonRow {
        display:grid;
        grid-template-columns: auto;
        grid-template-rows: auto auto;
        row-gap: 15px;
    }
}
