li.listImportant {
  font-weight: bold !important;
}

.spacing {
  margin-bottom: 20px !important;
}

.spacing ul {
  padding: 0;
  list-style-position: inside;
}

.containerFluid {
  padding: 100px 0;
  background-color: #eff2f6;
}

@media screen and (max-width: 768px) {
  .containerFluid {
    padding: 60px 0;
  }
  .outer p {
    font-size: 14px !important ;
  }
  .outer li {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 450px) {
  .outer {
    padding: 0 15px;
  }
}
