.shortDescription {
  color: #0d1531 !important;
}

.title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #0d1531;
}

.description {
  padding: 16px 80px 0 !important;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.4px;
}

.nameDescription {
  font-size: 14px !important;
}

@media screen and (max-width: 1200px) {
  .description {
    padding: 16px 40px 0 !important;
  }
}

@media screen and (max-width: 910px) {
  .description {
    padding: 16px 10px 0 !important;
  }
}
@media screen and (max-width: 450px) {
  .wrapper .description {
    font-size: 14px !important;
  }
  .wrapper {
    padding: 0 15px;
  }
  .nameDescription {
    font-size: 12px !important;
  }
  .title {
    font-size: 13px;
  }
}
