div .CookieConsent {
  font-family: 'Inter', sans-serif;
  text-align: center;
  width: 330px !important;
  background: #FFF !important;
  box-shadow: 0 0 20px 4px rgba(0,0,0, 0.16) !important;
  border-radius: 8px !important;
  color: #0C1431 !important;
  margin: 12px !important;
}

.cookiestitle {
  font-size: 14px !important;
  font-weight: bold !important;
}

#rcc-decline-button {
  width: 141px !important;
  margin-left: 0px !important;
  margin-right: 16px !important;
  margin-top: 0px !important;
  /* border: 1px solid #EC004F !important; */
  background: #ECECEC !important;
  color: #0C1431 !important;
  font-size: 12px !important;
  font-weight: bold !important;
  border-radius: 8px !important;
  height: 44px !important;
}

#rcc-confirm-button {
  width: 141px !important;
  margin-left: 16px !important;
  margin-right: 16px !important;
  margin-top: 0px !important;
  background: #EC004F !important;
  color: white !important;
  font-size: 12px !important;
  font-weight: bold !important;
  border-radius: 8px !important;
  height: 44px !important;
}
