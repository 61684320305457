.projectDescription p,
.projectDescription h3 {
  margin-left: 20px !important;
}

div.gridWrapper .projectDescription h3 {
  color: #0d1531 !important;
  padding: 0 !important;
  margin-bottom: 8px !important;
}

div.gridWrapper .projectDescription h3:first-child {
  margin-top: 20px !important;
}

.projectDescription p {
  margin-bottom: 32px !important;
}

.logoBackground {
  width: 100%;
  height: 350px;
  border-radius: 8px;
  background-color: #eff2f6;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 992px) {
  .section {
    padding: 60px 0;
  }
}

@media screen and (max-width: 450px) {
  .gridWrapper {
    padding: 0 15px;
  }
  .logoBackground {
    margin-bottom: 24px;
  }
}
