.Frame {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  background-color: #eff2f6;
  border-radius: 50%;
}
.Frame.square {
  border-radius: 8px !important;
}
.Frame.large {
  width: 64px;
  height: 64px;
}

.Frame.normal {
  width: 48px;
  height: 48px;
}
.Frame.small {
  width: 32px;
  height: 32px;
}
.Frame.primary {
  background-color: #6236ff;
}
.Frame.secondary {
  background-color: #ec004f;
}
.Frame.white {
  background-color: #ffffff;
}

.frame-wrapper {
  display: flex;
  align-items: center;
}
.frame-wrapper > div {
  margin-right: 15px;
}

.dark .Frame,
.Frame.navy {
  background-color: #121b3f !important;
}

.dark .Frame svg path {
  fill: #ffffff;
}

.dark .Frame.grey {
  background-color: #eff2f6 !important;
}

.dark .Frame.grey svg path {
  fill: #18214d;
}

.Frame.navy svg path {
  fill: #dfe4ea;
}
