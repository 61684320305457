.titleBlock h2 {
  padding-top: 16px !important;
  padding-bottom: 20px !important;
}

.titleBlock p {
  padding-bottom: 60px !important;
}

.features {
    padding-bottom: 0;
    padding-top: 54px !important;
}
.features  img {
    height: 100%;
}
.features .leftColumn {
    display: flex;
    justify-content: center;
    align-items: center;
}
.features .leftColumn div {
    display: flex;
    align-items: center;
    height: 100%;
}

.description {
    padding-top: 4px;
}

.spacingBottom {
  margin-bottom: 44px;
}

.features div[class*="Divider"] {
    margin-top: 50px !important;
    margin-bottom: 32px !important;
    background: rgba(0, 0, 0, 0.15);
}
.logosRow {
}

@media screen and (max-width: 991px) {
    .features  img {
        height: 100%;
        max-height: 440px;
    }

    .logosRow {
        padding-top: 40px;
        padding-bottom: 60px;
    }
}
@media screen and (max-width: 768px) {

    .logosRow {
        padding-top: 40px;
        padding-bottom: 60px;
    }

}

@media screen and (max-width: 450px) {

    .gridWrapper {
        padding-left: 15px;
        padding-right: 15px;
    }

    .features img {
        max-height: initial;
    }
    .features a[class*="PlayButton"] {
        width: 48px !important;
        height: 48px !important;
    }
    .features a[class*="PlayButton"] svg {
        width: 48px !important;
        height: 48px !important;
    }

    .logosRow {
        padding-top: 20px;
        padding-bottom: 60px;
    }
}
