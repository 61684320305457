.boringstuff {
  padding: 54px 0 54px 0;
}

.legal {
  padding: 44px 0 100px 0;
}

.title {
  padding-bottom: 24px !important;
}

.boringstuff p {
  line-height: 1.2 !important;
  padding-bottom: 12px !important;
}

.boringstuff h2 {
  padding-bottom: 16px !important;
}

.boringstuff h3 {
  padding-bottom: 16px !important;
  padding-top: 8px !important;
}
