.footer {
    padding-top: 60px;
    padding-bottom: 55px;
}
.companyItem a {
    font-style: normal;
    font-size: 16px !important;
    line-height: 26px !important;
    letter-spacing: -0.2px !important;
    color: rgba(255, 255, 255, 0.70);
    text-decoration: none;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
.companyItem a:hover {
  color: #fff;
}
.description {
    padding-right: 20px !important;
    padding-top: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 26px !important;
    letter-spacing: -0.2px !important;
    color: #3E4462;
}
.companyList {
    margin: 0;
    padding: 0;
    list-style: none;
}
 .button {
    margin-top: 24px;
}

.footer header {
    padding-bottom: 16px;

    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.4px;
    color: #FFF;
}
.companyItem {
    padding-top: 8px;
}
.dividerRow {
    padding-top: 60px;
    padding-bottom: 35px;
}
.shortDescription {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 26px !important;
    letter-spacing: -0.2px !important;
    color: #3E4462 !important;
}
.boldLink {
    display: block;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    letter-spacing: -0.4px !important;
    color: rgba(255, 255, 255, 0.70);
    text-decoration: none !important;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
.boldLink:nth-last-child(2) {
    padding-top: 22px;
}
.boldLink:nth-last-child(1) {
    padding-top: 15px;
}
.boldLink:hover {
    color: #FFF;
}
.copyright {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 26px !important;
    letter-spacing: -0.2px !important;
    color: #FFF !important;
}
.rightColumn {
    padding-top: 10px;
}
.dark.footer header {
    color: #ffffff;
}
.dark .companyItem a {
    color: rgba(255, 255, 255, 0.5);
    mix-blend-mode: normal;
}
.dark .companyItem a:hover {
    color: rgba(255, 255, 255, 1);
}
.dark.footer .shortDescription,
.dark.footer .copyright {
    color: rgba(255, 255, 255, 0.5) !important;
    mix-blend-mode: normal;
}
.dark .boldLink {
    color: #ffffff !important;
}
.dark .boldLink:hover {
    color: rgba(255, 255, 255, 0.5) !important;
}
.dark .logotype svg path {
    fill: #ffffff;
}

@media screen and (max-width: 991px) {
    .footer {
        padding-top: 40px;
        padding-bottom: 45px;
    }
    .item {
        margin-bottom: 20px;
    }
    .item:nth-last-child(1) {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 768px) {
    .rightColumn {
        padding-top: 50px;
    }
    .description {
        max-width: 400px;
    }
    .shortDescription {
        max-width: 300px;
    }
}
@media screen and (max-width: 575px) {
    .socials {
        margin-top: 30px;
        justify-content: flex-start !important;
    }
}
@media screen and (max-width: 450px) {
    .footer {
        padding-top: 30px;
        padding-bottom: 35px;
    }
    .gridWrapper {
        padding-left: 15px;
        padding-right: 15px;
    }
    .footer .item {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .shortDescription {
        max-width: 250px;
    }
    .dividerRow {
        padding-top: 25px;
        padding-bottom: 25px;
    }
}
