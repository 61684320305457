.portfolio {
  padding-top: 28px;
}

h5 {
  padding-top: 4px;
}

.portfolioColumn .item:nth-child(even) {
    margin-top: -30px;
}
.portfolioColumn  .item {
    margin-bottom: 30px;
}

.portfolioColumn .item:nth-last-child(-n+2) {
    margin-bottom: 0;
}
.buttonRow {
    padding-top: 60px;
}

.bottomLevel{
  padding-top: 12px;
}

.pictureLink {
    display: block;
    cursor: pointer;
    -webkit-transition: opacity 0.2s ease-in-out ;
    -o-transition: opacity 0.2s ease-in-out ;
    transition: opacity 0.2s ease-in-out ;
}

.pictureLink:hover {
    opacity: 0.7 !important;
}

@media screen and (max-width: 991px) {
    .portfolio img {
        height: auto;
    }
}

@media screen and (max-width: 768px) {
    .portfolioColumn .item:nth-child(even) {
        margin-top: 0;
    }
    .portfolioColumn {
        padding-top: 40px;
    }
    .buttonRow {
        padding-top: 40px;
    }
}
@media screen and (max-width: 450px) {
    .gridWrapper {
        padding-left: 15px;
        padding-right: 15px;
    }
    .portfolioColumn .item {
        padding-left: 10px !important;
        padding-right: 10px !important;
        margin-bottom: 20px;
    }
}
